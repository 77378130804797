import axios from 'axios';
import {
  IDNETITY_IN_TEST_REQUEST,
  IDNETITY_IN_TEST_SUCCESS,
  IDNETITY_IN_TEST_FAILURE,
} from './statusTypes';

export const IdentityINTestRequest = () => {
  return {
    type: IDNETITY_IN_TEST_REQUEST,
  };
};

export const IdentityINTestSuccess = (shortName, country, stage) => {
  return {
    type: IDNETITY_IN_TEST_SUCCESS,
    payload: {
      status: 'Active',
      lastRequest: new Date().toLocaleString(),
    },
    key: shortName,
    stage: stage,
    country: country,
  };
};

export const IdentityINTestFailure = (error) => {
  return {
    type: IDNETITY_IN_TEST_FAILURE,
    payload: error,
  };
};

export const statusChecker = (shortName, country, stage, URL) => {
  return (dispatch) => {
    dispatch(IdentityINTestRequest());
    // initiate Request
    axios
      .get(URL)
      .then((response) => {
        const status = response.data;
        console.log(status);
        if (status) {
          dispatch(IdentityINTestSuccess(shortName, country, stage));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(IdentityINTestFailure(errorMsg));
      });
  };
};
