import {
  IDNETITY_IN_TEST_REQUEST,
  IDNETITY_IN_TEST_SUCCESS,
  IDNETITY_IN_TEST_FAILURE,
} from './statusTypes';

const initialState = {
  loading: false,
  responses: {},
  error: '',
  isLoggedIn: false,
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case IDNETITY_IN_TEST_REQUEST:
      return { ...state, loading: true, error: '' };
    case IDNETITY_IN_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        responses: {
          ...state.responses,
          [`${action.key}-${action.country}-${action.stage}`]: action.payload,
        },
      };
    case IDNETITY_IN_TEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default statusReducer;
