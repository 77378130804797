import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statusChecker } from '../redux';
import { Button } from 'react-bootstrap';
import alarm from '../assets/alarm.wav';

const Status = ({ index, serviceInfo }) => {
  const dispatch = useDispatch();

  const [service, setService] = useState(serviceInfo);
  console.log(service);

  const response = useSelector((state) => state.status.responses);

  useEffect(() => {
    const interval = setInterval(() => {
      checkNow();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  function checkNow() {
    dispatch(
      statusChecker(
        service.shortName,
        service.country,
        service.stage,
        service.URL
      )
    );
  }

  const [audio, setAudio] = useState(false);

  function alarmHandler() {
    setAudio(!audio);
    const alarmSound = new Audio(alarm);
    if (audio) {
      alarmSound.play();
    } else {
      alarmSound.pause();
    }
  }

  const [isActive, setIsActive] = useState(false);
  const [lastTested, setLastTested] = useState('');

  useEffect(() => {
    console.log(response);
    if (response) {
      const variable = `${service.shortName}-${service.country}-${service.stage}`;
      const serviceName = response[variable];
      if (serviceName) {
        setIsActive(true);
        setLastTested(serviceName['lastRequest']);
      } else {
        alarmHandler();
      }
    }
  }, [response]);

  return (
    <tr>
      <th>{index}</th>
      <td>{service.name}</td>
      <td>{service.shortName}</td>
      <td>{service.country}</td>
      <td>{service.stage}</td>
      <td>{isActive ? 'Active' : 'Not Active'}</td>
      <td>{lastTested}</td>
      <td>
        <Button onClick={checkNow}>Check Now</Button>
      </td>
    </tr>
  );
};

export default Status;
