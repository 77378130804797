import React from 'react';
import { Card } from 'react-bootstrap';
import Status from './Status';

const Services = () => {
  const services = [
    {
      name: 'Identity Service',
      shortName: 'identity',
      country: 'IN',
      stage: 'TEST',
      URL: 'https://test-identity.prooof.in/test/',
      message: 'Identity Server Started from IN-TEST server',
    },
    {
      name: 'Identity Service',
      shortName: 'identity',
      country: 'IN',
      stage: 'PROD',
      URL: 'https://identity.prooof.in/test/',
      message: 'Identity Server Started from IN-PROD server',
    },
    {
      name: 'Profile Service',
      shortName: 'profiles',
      country: 'IN',
      stage: 'TEST',
      URL: 'https://test-profiles.prooof.in/test/',
      message: 'Profile Server Started from IN-TEST server',
    },
    {
      name: 'Profile Service',
      shortName: 'profiles',
      country: 'IN',
      stage: 'PROD',
      URL: 'https://profiles.prooof.in/test/',
      message: 'Profile Server Started from IN-PROD server',
    },
    {
      name: 'Academic Service',
      shortName: 'academics',
      country: 'IN',
      stage: 'TEST',
      URL: 'https://test-academics.prooof.in/test/',
      message: 'Academic Server Started from IN-TEST server',
    },
    {
      name: 'Academic Service',
      shortName: 'academics',
      country: 'IN',
      stage: 'PROD',
      URL: 'https://academics.prooof.in/test/',
      message: 'Academic Server Started from IN-PROD server',
    },
    {
      name: 'Advertisement Service',
      shortName: 'advertisement',
      country: 'IN',
      stage: 'TEST',
      URL: 'https://test-ele.prooof.in/test/',
      message: 'Advertisement Server Started from IN-TEST server',
    },
    {
      name: 'Advertisement Service',
      shortName: 'advertisement',
      country: 'IN',
      stage: 'PROD',
      URL: 'https://ele.prooof.in/test/',
      message: 'Advertisement Server Started from IN-PROD server',
    },
    {
      name: 'Billing Service',
      shortName: 'billing',
      country: 'IN',
      stage: 'TEST',
      URL: 'https://test-billing.prooof.in/test/',
      message: 'Billing Server Started from IN-TEST server',
    },
    {
      name: 'Billing Service',
      shortName: 'billing',
      country: 'IN',
      stage: 'PROD',
      URL: 'https://billing.prooof.in/test/',
      message: 'Billing Server Started from IN-PROD server',
    },
    {
      name: 'HRM Service',
      shortName: 'hrms',
      country: 'IN',
      stage: 'TEST',
      URL: 'https://test-hrms.prooof.in/test/',
      message: 'HRMS Server Started from IN-TEST server',
    },
    {
      name: 'HRM Service',
      shortName: 'hrms',
      country: 'IN',
      stage: 'PROD',
      URL: 'https://hrms.prooof.in/test/',
      message: 'HRMS Server Started from IN-PROD server',
    },
  ];

  console.log(services[0]);

  return (
    <div style={{ margin: 'auto', width: '50%' }}>
      <Card style={{ width: 1000 }}>
        <table className="table table-bordered tc">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Service Name</th>
              <th>Short Name</th>
              <th>Country</th>
              <th>Stage</th>
              <th>Status</th>
              <th>Last Requested</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <Status index={1} serviceInfo={services[0]} />
            <Status index={2} serviceInfo={services[1]} />
            <Status index={3} serviceInfo={services[2]} />
            <Status index={4} serviceInfo={services[3]} />
            <Status index={5} serviceInfo={services[4]} />
            <Status index={6} serviceInfo={services[5]} />
            <Status index={7} serviceInfo={services[6]} />
            <Status index={8} serviceInfo={services[7]} />
            <Status index={9} serviceInfo={services[8]} />
            <Status index={10} serviceInfo={services[9]} />
            <Status index={11} serviceInfo={services[10]} />
            <Status index={12} serviceInfo={services[11]} />
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default Services;
