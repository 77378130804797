import './App.css';
import Services from './modules/Services';

function App() {
  return (
    <div style={{ width: '100%', height: '100%' }} className="App tc">
      <h1>Alarms</h1>
      <div style={{ width: '100%', height: '100%' }} className="App tc">
        <Services />
      </div>
    </div>
  );
}

export default App;
